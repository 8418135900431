import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.less';
import GameHash from './components/GameHash';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/">
                    <Route index element={<GameHash />} />
                    <Route path={':id/:hash'} element={<GameHash />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
