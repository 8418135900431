import React, { useEffect, useState } from 'react';

import { Button, Col, Divider, Input, Row, Select, Spin } from 'antd';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import ImgIcon from '../common/components/ImgIcon';
import './GameHash.less';
import { GAMES } from '../common/constants';
import { API } from '../constansts/api';

const { HOST, APIBASE, GAMEHASH } = API;
const GameHash = () => {
    const { Option } = Select;
    const [formInputs, setFormInputs] = useState({});
    const [result, setResult] = useState('');
    const [loading, setLoading] = useState(false);
    let { id, hash } = useParams();

    useEffect(() => {
        setFormInputs({ hash: hash, gameTypeId: id ? parseInt(id) : null });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, hash]);

    const formSubmit = () => {
        setLoading(true);

        axios
            .post(`${HOST}${APIBASE}${GAMEHASH}`, {
                ...formInputs,
            })
            .then(function (response) {
                if (response.data.statusCode !== 200) hasError();
                else setResult(response.data.result);
            })
            .catch(function () {
                hasError();
            })
            .finally(setTimeout(() => setLoading(false), 500));
    };

    const hasError = () => setResult(' ERROR!');
    const onChange = (value, field) => setFormInputs({ ...formInputs, [field]: value });

    return (
        <div className="App">
            <Col span={24} className="hash-container">
                <div>
                    <ImgIcon
                        src={require('../assets/images/logo.png')}
                        width={'25%'}
                        height={'20%'}
                    />
                    <br />
                    <h1 className="header-text">Game Hash Checking</h1>
                    <Row justify="space-between">
                        <Col span={11}>
                            <div className="row-input">
                                <h3 className="header-text">Game</h3>
                                <Select
                                    style={{ width: '100%' }}
                                    onChange={(value) => {
                                        onChange(value, 'gameTypeId');
                                    }}
                                    value={formInputs.gameTypeId}
                                >
                                    {Object.keys(GAMES).map((game) => {
                                        return (
                                            <Option value={GAMES[game]} key={game}>
                                                {game}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </div>

                            <div className="row-input">
                                <Button onClick={formSubmit}>Verify</Button>
                            </div>
                        </Col>
                        <Col span={11}>
                            <div className="row-input">
                                <h3 className="header-text">Game Hash</h3>
                                <Input
                                    onChange={(e) => onChange(e.target.value, 'hash')}
                                    value={formInputs.hash}
                                />
                            </div>
                            <div className="row-input">
                                <span className="header-text">
                                    {loading ? <Spin /> : `Game results: ${result}`}
                                </span>
                            </div>
                        </Col>
                    </Row>

                    <Divider />
                    <h1 className="header-text">Provably Fairness</h1>
                    <h2>What is Provably Fairness?</h2>
                    <span>
                        Provably Fairness is a concept of providing users the ability to verify the
                        result generated by our game and to show that these results are all fair and
                        not manipulated / pre-determined. To confirm user could use the game hash
                        provided in the game to calculate the game result for each game
                    </span>
                </div>
            </Col>
        </div>
    );
};

export default GameHash;
