import React from 'react';

import PropTypes from 'prop-types';

const ImgIcon = (props) => {
    const styles = {
        cursor: 'pointer',
        height: `${props.height ? props.height : 'auto'}`,
        width: `${props.width ? props.width : 'auto'}`,
    };

    return <img src={props.src} style={styles} alt={props.alt} />;
};

ImgIcon.propTypes = {
    alt: PropTypes.string,
    src: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ImgIcon;
